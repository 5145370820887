// src/components/ServicesPage.js
import React from 'react';
import {NavBar} from './NavBar.js';
import Footer from './FotterPage.js';
import CruiseImage from './img/cruise.jpg'; // Import images or use placeholders
import VacationPackageImage from './img/travel1.jpg';
import GroupTravelImage from './img/travel2.jpg';
import PilgrimageImage from './img/church1.jpg';
import HotelsDealsImage from './img/hotel1.jpg';
import PackageVacation from './img/package1.jpg';
import MedicalTreatmentsImage from './img/medical.jpg';
import CarRentalsImage from './img/carrental.jpg';
import VIPServicesImage from './img/luxury.jpg';
import UmrahImage from './img/umrah.jpg';

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import './css/service-css.css'

const ServicesPage = () => (
    <div>
      <NavBar />
  
      <div className="container mt-5">
        <h2 className="text-center mb-5">Our Services</h2>

         {/* Vacation Packages Section */}
      {/* <div className="vacation-packages-container">
        <img src={PackageVacation} alt="Vacation Packages" className="vacation-packages-image" />
        <div className="vacation-packages-content">
          <h3>Vacation Packages</h3>
          <p>What our packages include:</p>
          
          <p>  Airfare to your destination / Accommodations in top-rated hotels / Train rides with scenic views </p>
  
          <p>Book your all-inclusive vacation package now and create lasting memories.</p>
        </div>
      </div> */}

      {/* Add more service containers for other services */}
        <div className="row">
          {/* Cruise Service */}
          <div className="col-md-6">
            <div className="service-container">
              <img src={VacationPackageImage} alt="Cruise Service" className="service-image" />
              <div className="service-content">
                <h3>Vacation Packages</h3>
                <p>Discover our curated vacation packages that include flights, hotels, transfers, tours, and more.</p>
              </div>
            </div>
          </div>

          {/* Vacation Packages */}
          <div className="col-md-6">
            <div className="service-container">
              <img src={CruiseImage} alt="Vacation Packages" className="service-image" />
              <div className="service-content">
                <h3>Cruises</h3>
                <p>Embark on a luxurious cruise experience with Rainbow Travel. Explore the seas in style and comfort.</p>
              </div>
            </div>
          </div>
  
          {/* Fully Serviced Group Travel */}
          <div className="col-md-6">
            <div className="service-container">
              <img src={PilgrimageImage} alt="Fully Serviced Group Travel" className="service-image" />
              <div className="service-content">
                <h3>Christian Pilgrimage</h3>
                <p>Embark on a spiritual journey with our Christian Pilgrimage tours to the Holy Land. Fully serviced from Airport to Airport.</p>
              </div>
            </div>
          </div>
  
          {/* Christian Pilgrimage */}
          <div className="col-md-6">
            <div className="service-container">
              <img src={GroupTravelImage} alt="Christian Pilgrimage" className="service-image" />
              <div className="service-content">
                <h3>Exclusive Hotels Deals</h3>
                <p>Book your dream stay today and enjoy a memorable experience.</p>
              </div>
            </div>
          </div>
        </div>
        
          {/* Hotels Deals Section */}
      <div className="hotels-deals-container">
        <img src={HotelsDealsImage} alt="Hotels Deals" className="hotels-deals-image" />
        <div className="hotels-deals-content">
              <h3>Fully Serviced Group Travel</h3>
              <p>Experience the joy of group travel to Europe and Middle East with Rainbow Travel. We handle all the details for a seamless journey.</p>  
        </div>
      </div>
          {/*end Hotels Deals Section */}

         {/* Umrah Travel Section */}
      <div className="row">
        <div className="col-md-6">
          <div className="service-container">
            <img src={UmrahImage} alt="Umrah Travel" className="service-image" />
            <div className="service-content">
              <h3>Umrah Travel and Services</h3>
              <p>Embark on a spiritual journey with our Umrah travel services. Experience the sacred pilgrimage with Rainbow Travel.</p>
            </div>
          </div>
        </div>

        {/* VIP Services Section */}
        <div className="col-md-6">
          <div className="service-container">
            <img src={CarRentalsImage} alt="Car Rentals" className="service-image" />
            <div className="service-content">
              <h3>Car Rentals</h3>
              <p>Explore your destination at your own pace with our car rental services. Choose from a variety of vehicles for a comfortable journey.</p>
            </div>
          </div>
        </div>
      </div>
      {/* Add more service containers for other services */}

      </div>
  
      <Footer />
    </div>
  );
  
  export default ServicesPage;