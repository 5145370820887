import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './css/footer-css.css'; 
import {FacebookIcon, InstagramIcon, TwitterIcon,FillPerson} from './SocialIcons.js';
import logoImage from './img/logo.jpeg';

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-4" >
      <div className="container">
        
        <div className="row">
         
          <div className="col-md-3 mb-4  footer-divs">
            
            </div>
          {/* Working Hours */}
          <div className="col-md-3 mb-4  footer-divs">
            <h5>Working Hours</h5>
            <p className="your-paragraph-class">Monday - Friday: 9 AM - 5 PM</p>
            <p className="your-paragraph-class">Saturday: per appointment only</p>
            <p className="your-paragraph-class">Sunday: closed</p>
          </div>
          
          {/* Office Location */}
          <div className="col-md-3 mb-4 footer-divs">
            <h5>Office Location</h5>
            <p className="your-paragraph-class">24619 Ford Rd, Dearborn, MI 48128, United States</p>
            <p className="your-paragraph-class">Office number: +1 313-582-3322</p>
          </div>
          
        </div>
        {/* Social Media Links */}
        <div className="mb-4">
          <h5>Follow Us</h5>
          <div className="d-flex justify-content-center">
            <a href="https://www.facebook.com/rtsfares/" target="_blank" rel="noopener noreferrer" className="me-3">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/rtsfares/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
          </div>
        </div>

        {/* Report a Problem Link */}
        <div>
          <h5>Report a Problem</h5>
          <p className="your-paragraph-class">
            If you encounter any issues, please{' '}
            <a href="mailto:info@rtsfares.com">contact support</a>.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;