// src/components/HomePage.js
import React, { useState,useEffect, useRef }  from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import { ReactSVG } from 'react-svg'; // Import ReactSVG
import { createRoot } from 'react-dom/client'
import {FacebookIcon,InstagramIcon,TwitterIcon,FillPerson} from './SocialIcons.js';
import {NavBar} from './NavBar.js';
import Footer from './FotterPage.js';
import ContactPage from './ContactPage.js';
import './css/navBar.css';
import './css/HomePage.css';
import './css/firsthome-css.css';
import './css/firsthomevideo-css.css';

import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap styles
import { Container, Row, Col } from 'react-bootstrap' // Bootstrap styles
import { Carousel } from 'react-bootstrap';

import logoImage from './img/logo.jpeg';


import firesthomeimagecoursel1 from './img/coursel1.jpg';
import firesthomeimagecoursel2 from './img/coursel2.jpg';
import firesthomeimagecoursel3 from './img/coursel3.jpg';
import firesthomeimagecoursel4 from './img/coursel4.jpg';
import firesthomeimagecoursel5 from './img/coursel5.jpg';
import firesthomeimagecoursel6 from './img/coursel6.jpg';



const FirstHome = () => {
  return (
    <div className="first-home-container mt-5">
      <h1 className="first-home-title coursel-imgs">Explore Beautiful Destinations</h1>
      <Carousel>
        <Carousel.Item className="first-home-carousel-item">
          <img
            className="d-block w-100"
            src={firesthomeimagecoursel1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Discover the wonders of the world with Rainbow Travel</h3>
            <p>Dubai</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="first-home-carousel-item">
          <img
            className="d-block w-100"
            src={firesthomeimagecoursel2}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Discover the wonders of the world with Rainbow Travel</h3>
            <p>Norway</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="first-home-carousel-item">
          <img
            className="d-block w-100"
            src={firesthomeimagecoursel3}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Discover the wonders of the world with Rainbow Travel</h3>
            <p>Maldives</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="first-home-carousel-item">
          <img
            className="d-block w-100"
            src={firesthomeimagecoursel4}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>Discover the wonders of the world with Rainbow Travel</h3>
            <p>Greece</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="first-home-carousel-item">
          <img
            className="d-block w-100"
            src={firesthomeimagecoursel5}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>Discover the wonders of the world with Rainbow Travel</h3>
            <p>Egypt</p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="first-home-carousel-item">
          <img
            className="d-block w-100"
            src={firesthomeimagecoursel6}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Discover the wonders of the world with Rainbow Travel</h3>
            <p>Mexico</p>
          </Carousel.Caption>
          </Carousel.Item>

        {/* Add more Carousel.Items as needed with different images and captions */}
      </Carousel>
    </div>
  );
};


const FirstHomeVideos = () => {
  return (
    <Container className="mt-5">
      <h1 className="mb-4 yutube-videos">Check out these wonderful places to visit</h1>
      <center>
      <Row>
        <Col md={4} className="mb-2">
          <iframe
            title="Video 1"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/Z755vZBf9oM"
            allowFullScreen
          ></iframe>
          <p className="video-description">
            Explore the best places in Maldives.
          </p>
        </Col>
        <Col md={4} className="mb-2">
          <iframe
            title="Video 2"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/hQItqD6ERLM"
            allowFullScreen
          ></iframe>
          <p className="video-description">
            Discover the vibrant nature of Switzerland.
          </p>
        </Col>
        <Col md={4} className="mb-2">
          <iframe
            title="Video 3"
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/KLuTLF3x9sA"
            allowFullScreen
          ></iframe>
          <p className="video-description">
            Experience the beauty of Norway.
          </p>
        </Col>
      </Row>
      </center>
    </Container>
  );
};
  

// NavigationBar component
<NavBar />
const HeroSection = () => (
  <div className="hero-section">
    <div className="hero-background"></div>
    <div className="hero-content">
      <h1>Welcome to Rainbow Travel</h1>
      <p>Embark on a journey of a lifetime with our extraordinary travel packages.</p>
      <Link to="/ServicePage">
      <button className="explore-button">
        Explore Now
      </button>
      </Link>
    
    </div>
  </div>
);

const ImageSection = () => (
  <div className="image-section">
    <img src="img/image.jpg" alt="Travel Destinations" />
  </div>
);

const GeneralInfoSection = () => (
  <div className="general-info-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <h2>Welcome to Rainbow Travel</h2>
          <p>Rainbow travel has been in business since the 1980s serving the local communicty in Dearborn MI and beyond
          A trusted name in the community  with many years of experience under its sleeves. A family owned business that grew along the years with the loyalty of its clientelle in the local community </p>
          <p>Our team of experts, located in Lebanon, Egypt, and Europe, is dedicated to customizing your next trip to make it as smooth as possible. We strongly believe that what is impossible is simply untried.</p>
        </div>
        <div className="col-md-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  {/* Add an image or illustration here */}
            <img src={logoImage} alt="Rainbow Travel" className="img-fluid logo-circle" />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-6">
          <p>Our passion for travel, joined with our expertise, has led us to steady growth. We are excited to announce our new vacations department, specializing in custom-building vacation packages to destinations ranging from the Caribbean and Southern America to various places in Europe, the Middle East, and the Orient, and captivating destinations like Mexico.</p>
        </div>
        <div className="col-md-6">
          <p>Our DMC partners in Greece, Turkey, Cyprus, Italy, Czechia, Palestine, and Dubai have over 25 years of experience and market dominance, strengthening our presence in those particular markets.</p>
        </div>
      </div>
    </div>
  </div>
);

const LatestAffiliationContainer = () => (
  <div className="latest-affiliation-container">
    <h2>Our Latest Affiliation with Mondee</h2>
    <p>
      We are excited to announce our recent affiliation with Mondee, a leading travel technology company.
      This partnership gives us a competitive edge, ensuring you the lowest prices on the planet!
    </p>
    <p>
      Mondee's innovative solutions empower us to provide you with unparalleled travel experiences and unbeatable deals.
      Whether you're planning a getaway to the Carribean, Southern America, Europe, the Middle East, or the Orient,
      our affiliation with Mondee ensures that you get the best value for your travel investment.
    </p>
    <p>
      Trust Rainbow Travel to bring you the latest advancements in travel technology and the most affordable prices
      for your dream vacations.
    </p>
  </div>
);

const FeaturedPackages = () => (
  <div className="featured-packages">
  <h2>Featured Packages</h2>
  <div className="row">
    <div className="col-md-4 mb-4">
      <div className="card">
        <img src="/path/to/package1.jpg" alt="Package 1" className="card-img-top" />
        <div className="card-body">
          <h5 className="card-title">Package 1</h5>
          <p className="card-text">Description of Package 1.</p>
          <Link to="/package1" className="btn btn-primary">
            Learn More
          </Link>
        </div>
      </div>
    </div>
    <div className="col-md-4 mb-4">
      {/* Add more featured packages */}
    </div>
    <div className="col-md-4 mb-4">
      {/* Add more featured packages */}
    </div>
  </div>
</div>
);

const ContactSection = () => (
  <div className="contact-section">
    <h2>Contact Us</h2>
    <p>Have questions or ready to book your next adventure?</p>
    
    <Link to="/ContactPage" className="contact-button">
      Contact Us
    </Link>
  </div>
);

const HomePage = () => (
  <div className="home-container">
     <NavBar />
    <HeroSection />
    <FirstHome />
    <FirstHomeVideos />
    <GeneralInfoSection />    
    <LatestAffiliationContainer />
    <ContactSection />
    <Footer />
  </div>
);

export default HomePage;
