import React, { useState,useEffect, useRef }  from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import {FacebookIcon,InstagramIcon,TwitterIcon,FillPerson} from './SocialIcons.js';
import SignupPage from './SignupPage.js';
import LoginPage from './LoginPage.js';
import ServicePage from './ServicePage.js';
import ContactPage from './ContactPage';
import logoImage from './img/logo.jpeg';

import logo2 from './img/logo2.jpg';
//<img src={logo2} className="logo-img"/>
const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className={`navbar ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
      <div className="logo">
        <img src={logoImage} alt="Rainbow Travel Logo" className="logo-image" />
        <Link to="/" onClick={closeMobileMenu}>Rainbow Travel</Link>
      </div>
      <div className={`nav-links ${isMobileMenuOpen ? 'show' : ''}`}>
        <Link to="/" className='homes' onClick={closeMobileMenu}>Home</Link>
        <Link to="/ServicePage" className='homes' onClick={closeMobileMenu}>Services</Link>
        <Link to="/ContactPage" className='homes' onClick={closeMobileMenu}>Contact-us</Link>
        <Link to="/ContactPage" className='homes' onClick={closeMobileMenu}></Link>
        <Link to="/ContactPage" className='homes' onClick={closeMobileMenu}></Link>
      </div>
      <div className="auth">
        <div className="d-flex justify-content-center">
          <a href="https://www.facebook.com/rtsfares/" target="_blank" rel="noopener noreferrer" className="me-3">
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/rtsfares/" target="_blank" rel="noopener noreferrer">
            <InstagramIcon />
          </a>
        </div>
      </div>
      <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export { NavBar };